import api from '../middleware/api';
import { TOP_REQUEST,
  TOP_SUCCESS,
  TOP_FAILURE } from '../constants/action-types';

import { DateOnly } from '../utils';

export function getTopData(type) {
  return (dispatch, getState) => {
    const { session, details } = getState().topReducer;
    if (session !== DateOnly() || !details) {
      dispatch(topDataRequest());
      api.fetchGet(`reco/chart/${type}`)
        .then(({ data }) => {
          dispatch(topDataSuccess(data));
        })
        .catch(() => {
          dispatch(topDataFailure('error'));
        });
    }
  };
}

// action creators
function topDataRequest(payload) {
  return { type: TOP_REQUEST, payload };
}
function topDataSuccess(payload) {
  return { type: TOP_SUCCESS, payload };
}
function topDataFailure(payload) {
  return { type: TOP_FAILURE, payload };
}
