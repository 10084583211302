import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { DateString } from '../../utils';
import menu from '../../assets/images/mMenu.png';
import './HamburgerMenu.css';

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleClickOutside = (event) => {
    // Close the menu if clicked outside of it
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <div className="hamburger-menu">
      <span className="menu-toggle"><img className='menu-img' src={menu} alt="topBarMenu"/></span>
      {isOpen && (
        <nav className="menu-nav" ref={menuRef}>
          <Link to={`/calendar/${DateString(new Date())}`} onClick={toggleMenu}>Calendar</Link>
          <Link to="/trending" onClick={toggleMenu}>Trending</Link>
          <Link to="/most-popular" onClick={toggleMenu}>Most Popular</Link>
        </nav>
      )}
    </div>
  );
};

export default HamburgerMenu;
