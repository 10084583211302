import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';

import MostPopularComponent from '../components/MostPopularComponent';
import { getTopData } from '../actions';
import { trackGA } from '../utils';
import { MOST_POPULAR } from '../constants/modules';

MostPopular.propTypes = {
  topDetails: PropTypes.object,
  getTopData: PropTypes.func
};

function MostPopular({ topDetails, getTopData }) {
  const location = useLocation();
  useEffect(() => {
    trackGA(MOST_POPULAR, location);
  }, [location]);
  useEffect(() => getTopData('Top'), []);
  return (
    <MostPopularComponent
      topDetails={topDetails}
    />
  );
}

const mapStateToProps = (state) => {
  const { topReducer } = state;
  return {
    topDetails: topReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTopData: (type) => dispatch(getTopData(type))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MostPopular);
