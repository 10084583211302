export const SEARCH_RESULT_REQUEST = 'SEARCH_RESULT_REQUEST';
export const SEARCH_RESULT_SUCCESS = 'SEARCH_RESULT_SUCCESS';
export const SEARCH_RESULT_FAILURE = 'SEARCH_RESULT_FAILURE';
export const SEARCH_RESULT_CLEAR = 'SEARCH_RESULT_CLEAR';

export const RECOS_REQUEST = 'RECOS_REQUEST';
export const RECOS_SUCCESS = 'RECOS_SUCCESS';
export const RECOS_FAILURE = 'RECOS_FAILURE';
export const CHECK_FILTERS = 'CHECK_FILTERS';
export const TOGGLE_FILTERS = 'TOGGLE_FILTERS';

export const NEW_REQUEST = 'NEW_REQUEST';
export const NEW_SUCCESS = 'NEW_SUCCESS';
export const NEW_FAILURE = 'NEW_FAILURE';

export const TRENDING_REQUEST = 'TRENDING_REQUEST';
export const TRENDING_SUCCESS = 'TRENDING_SUCCESS';
export const TRENDING_FAILURE = 'TRENDING_FAILURE';

export const TOP_REQUEST = 'TOP_REQUEST';
export const TOP_SUCCESS = 'TOP_SUCCESS';
export const TOP_FAILURE = 'TOP_FAILURE';



// -----------------------------------------------------------------------------
// Login/profile requests

// User authentication requests
export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const RECEIVE_LOGIN = 'RECEIVE_LOGIN';
// Failure is handled by the popup widget thingy

export const REQUEST_PROFILE = 'REQUEST_PROFILE';
export const RECEIVE_PROFILE = 'RECEIVE_PROFILE';
export const RECEIVE_PROFILE_FAILURE = 'RECEIVE_PROFILE_FAILURE';

export const LOGOUT = 'LOGOUT';
export const STORE_POST_LOGIN_REDIRECT = 'STORE_POST_LOGIN_REDIRECT';

export const REQUEST_UPDATE_PROFILE = 'REQUEST_UPDATE_PROFILE';
export const RECEIVE_UPDATE_PROFILE = 'RECEIVE_UPDATE_PROFILE';
export const REQUEST_UPDATE_PROFILE_FAILURE = 'REQUEST_UPDATE_PROFILE_FAILURE';

export const SET_OVERRIDE_ACCESS_TOKEN = 'SET_OVERRIDE_ACCESS_TOKEN';

// -----------------------------------------------------------------------------
