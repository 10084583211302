import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';

import TrendingComponent from '../components/TrendingComponent';
import { getTreanding } from '../actions';
import { trackGA } from '../utils';
import { TRENDING } from '../constants/modules';

Trending.propTypes = {
  trendingDetails: PropTypes.object,
  getTreanding: PropTypes.func
};
function Trending({ trendingDetails, getTreanding }) {
  const location = useLocation();
  useEffect(() => {
    trackGA(TRENDING, location);
  }, [location]);
  useEffect(() => getTreanding('Trending'), []);
  return (
    <TrendingComponent
      trendingDetails={trendingDetails}
    />
  );
}

const mapStateToProps = (state) => {
  const { trendingReducer } = state;
  return {
    trendingDetails: trendingReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTreanding: (type) => dispatch(getTreanding(type))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Trending);
