import { TOP_REQUEST,
  TOP_SUCCESS,
  TOP_FAILURE } from '../constants/action-types';
import { DateOnly } from '../utils';

const initialState = {
  loading: false,
  error: null,
  details: null,
  session: null
};
function topReducer(state = initialState, action) {
  switch (action.type) {
  case TOP_REQUEST:
    return {
      ...state,
      loading: true
    };
  case TOP_SUCCESS:
    return {
      ...state,
      loading: false,
      details: action.payload,
      session: DateOnly()
    };
  case TOP_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.payload
    };
  default:
    return state;
  }
}

export default topReducer;
