import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams to extract URL params
import styles from './HorizontalDayPicker.module.css';

const isSameDay = (date1, date2) => new Date(date1).toISOString().split('T')[0] === new Date(date2).toISOString().split('T')[0];

const HorizontalDayPicker = ({ dateArr, selectedDay, handleDayClick }) => {
  const { month, day, year } = useParams(); // Get the month, day, and year from the URL
  const [internalSelectedDay, setInternalSelectedDay] = useState(selectedDay); // Internal state for selected day
  const dateRefs = useRef({}); // Create a ref to store date elements

  // Update internalSelectedDay when URL parameters change
  useEffect(() => {
    if (month && day && year) {
      // Create a date object from the URL params (Note: month is 1-based, so we subtract 1)
      const dateFromUrl = new Date(year, month - 1, day);
      setInternalSelectedDay(dateFromUrl); // Set internal selected day to the date from URL
    }
  }, [month, day, year]); // Run this effect when the URL params change

  // Scroll to the selected day when internalSelectedDay changes
  useEffect(() => {
    if (internalSelectedDay && dateRefs.current[internalSelectedDay.toISOString()]) {
      const selectedDateElement = dateRefs.current[internalSelectedDay.toISOString()];
      selectedDateElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }
  }, [internalSelectedDay]); // Trigger scroll whenever selected day changes


  const getScroll = (date) => {
    handleDayClick(date);
    setInternalSelectedDay(date); // Update internal selected day on click
  };

  return (
    <div className={`${styles.horizontal_date_picker} hDatePicker`}>
      <div className={`${styles.date_scroll}`}>
        {dateArr.map((day) => (
          <div
            key={day}
            id={`hscroll_${day}`}
            ref={(el) => dateRefs.current[new Date(day).toISOString()] = el} // Assign reference to each date element
            className={`${styles.date_pill} ${isSameDay(internalSelectedDay, day) ? styles.selected : ''}`}
            onClick={() => getScroll(new Date(day))}
          >
            <div className={`${styles.day}`}>
              {new Date(day).toLocaleDateString('en-us', { day: 'numeric' })}
            </div>
            <div className={`${styles.weekday}`}>
              {new Date(day).toLocaleString('en-us', { weekday: 'short' })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

HorizontalDayPicker.propTypes = {
  dateArr: PropTypes.array.isRequired,
  selectedDay: PropTypes.instanceOf(Date),
  handleDayClick: PropTypes.func.isRequired,
};

export default HorizontalDayPicker;
