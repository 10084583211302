import React, { useState, useRef, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Wlogo from '../../assets/images/nxt.png';
import HamburgerMenu from './HamburgerMenu';
import { DateString } from '../../utils';

import styles from './AppHeader.module.css';

export default function AppHeader () {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle the state value
  };

  const handleClickOutside = (event) => {
    // Close the menu if clicked outside of it
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <Row className={`${styles.headerMenu} d-flex align-items-center justify-content-between`}>
      <Col md={2} xs={4} lg={2} sm={3} className={styles.headerHamburger}>
        <Link to="/" className="mainLogo">
          <img src={Wlogo} alt="Hlogo" className={`${styles.logo} __hLogo`} />
        </Link>
      </Col>
      <Col md={10} xs={8} lg={10} sm={9} className={`${styles.recHeader} rec__header d-flex justify-content-end`}>
        <div ref={menuRef} className={`${styles.menuWrap} mMenu d-flex justify-content-end ${isMenuOpen ? 'mOpen' : 'mHide'}`}>
          <Link to={`/calendar/${DateString(new Date())}`} onClick={toggleMenu} className={`mLink ${isActive(`/calendar/${DateString(new Date())}`)}`}>calendar</Link>
          <Link to="/trending" onClick={toggleMenu} className={`mLink ${isActive('/trending')}`}>trending</Link>
          <Link to="/most-popular" onClick={toggleMenu} className={`mLink ${isActive('/most-popular')}`}>most popular</Link>
        </div>
        <div className='mMenuIcon' onClick={toggleMenu}><HamburgerMenu /></div>
      </Col>
    </Row>
  );
}
