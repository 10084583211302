import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';


import Footer from './common/Footer';
import styles from './TrendingComponent.module.css';
// import trending from '../assets/images/hash.png';
import AppHeader from './common/AppHeader';
import playBtn from '../assets/images/play.png';
import mPlayBtn from '../assets/images/play_arrow.png';

TrendingComponent.propTypes = {
  trendingDetails: PropTypes.object
};
export default function TrendingComponent({trendingDetails}) {
  return (
    <Container fluid={true} className={`${styles.trending} trending`}>
      <Row className="fixHeader">
        <Container fluid={true}>
          <AppHeader />
        </Container>
      </Row>
      { (trendingDetails.loading) &&
        <div className="fullHeight fullHeightTrend">
          <Spinner animation="border" />
        </div>
      }
      { (trendingDetails.details && trendingDetails.details.details) &&
        <Trending
          trendingResults={trendingDetails.details.details}
        />
      }
      <Footer />
    </Container>
  );
}

Trending.propTypes = {
  trendingResults: PropTypes.array
};
export function Trending({trendingResults}) {
  const trendingRef = React.createRef();
  const [deviceType, setDeviceType] = useState('desktop');

  useEffect(() => {
    const windowWidth = window.innerWidth;
    if(windowWidth < 991.98) {
      window.addEventListener('scroll', scrollPage);
    }
  });

  // Set the device type on window resize
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 991.98) {
        setDeviceType('mobile');
      } else {
        setDeviceType('desktop');
      }
    };

    handleResize(); // Set initial device type
    window.addEventListener('resize', handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const scrollPage = () => {
    const header = trendingRef.current;
    if(header) {
      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
      if((window.scrollY + window.innerHeight) > (docHeight - 200)) {
        header.classList.add('stickyHeaderTrending');
      } else {
        header.classList.remove('stickyHeaderTrending');
      }
    }
  };
  return(
    <Row className={`${deviceType} typeBody`}>
      <Container fluid={true} className={`${styles.fullHeight}`} ref={trendingRef}>
        {/* <ul className={`${styles.pageTitleSection} fixTitle`}>
          <li className={`${styles.imgHolder}`}>
            <img src={trending} alt="Trending" className={`${styles.proPic}`}/>
          </li>
          <li>
            <h3 className={`${styles.title}`}>Trending</h3>
            <span className={`${styles.timestamp}`}>
              {getFormattedDate(trendingResults[0].metric_date)}
            </span>
          </li>
        </ul> */}
        <Row className="fixContent">
          <div className={`${styles.whiteBox} whiteBox w-100`}>
            <div className={`${deviceType} mobileView`}>
              <ToggleArrows date={trendingResults[0].metric_date}/>
            </div>
            <ul className={`${styles.mostPopularList} mainList`}>
              <div className={`${deviceType} desktopView`}>
                <ToggleArrows date={trendingResults[0].metric_date}/>
              </div>
              <Rows details={trendingResults} deviceType={deviceType} />
            </ul>
          </div>
        </Row>
      </Container>
    </Row>
  );
}

Rows.propTypes = {
  details: PropTypes.array,
  deviceType: PropTypes.string
};
function Rows({details, deviceType}) {
  const data = details[0].data.data;
  return (
    <>
      {data.map(result =>
        <li key={result.rank}>
          <ul className={`${styles.mostPopularSet}`}>
            <li className={`${styles.countTag}`}><span>{result.rank}</span></li>
            <li>
              <span className={`${styles.listTitle}`}><h3>{result.display_name ? result.display_name : result.name ? result.name : ''}</h3></span>
              <div className='trendActionBox d-flex'>
                {result.entity_type && (<a className='actionBtn d-flex align-items-center justify-content-center'>{result.entity_type}</a>)}
                {result.channel && (<a className='actionBtn d-flex align-items-center justify-content-center'>{result.channel ? result.channel.toString() : ''}</a>)}
                {(deviceType === 'desktop' && result.video_id) && <a className='d-flex align-items-center justify-content-center' target="_blank" rel="noopener noreferrer" href={`https://www.youtube.com/watch?v=${result.video_id}`} >
                  <img className="ml-2" src={playBtn} alt="playBtn" />
                </a>}
                {(deviceType === 'mobile' && result.video_id) && <a className='actionBtn trailerBtn d-flex align-items-center justify-content-center' target="_blank" rel="noopener noreferrer" href={`https://www.youtube.com/watch?v=${result.video_id}`}>
                  <span className='info'>Trailer</span>
                  <img className="ml-1" src={mPlayBtn} alt="playBtn" />
                </a> }
              </div>
              {/* <ul className={`${styles.metaList}`}>
                <li><span><EllipsisWithTooltip placement="bottom">{result.entity_type}</EllipsisWithTooltip></span></li>
                <li><span><EllipsisWithTooltip placement="bottom">{result.channel ? result.channel.toString() : ''}</EllipsisWithTooltip></span></li>
                <li><span><EllipsisWithTooltip placement="bottom">{result.genre ? result.genre.toString() : ''}</EllipsisWithTooltip></span></li>
              </ul> */}
            </li>
            {/* <li className={`${styles.statusIcon}`}>
              <Button className={`${result.trend === 'Up'? styles.up : result.trend === 'Down'? styles.down : styles.neutral} `}></Button>
            </li> */}
          </ul>
        </li>
      )}
    </>
  );
}

ToggleArrows.propTypes = {
  toggleView: PropTypes.func,
  date: PropTypes.string
};
function ToggleArrows({ date}) {
  return (
    <ul className={`${styles.time} d-flex flex-column displayTxt`}>
      <li>
        <span>Week of </span>
        <span>{getFormattedDate(date)}</span>
      </li>
      <span className='titleTxt'>trending</span>
    </ul>
  );
}

function getFormattedDate(fDate) {
  const d = new Date(fDate);
  var month = d.toLocaleString('en-US', {month: 'short'});
  var date = d.getDate();
  var year = d.getFullYear();
  return `${month} ${date}, ${year}`;
}
