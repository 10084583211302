import api from '../middleware/api';
import { NEW_REQUEST,
  NEW_SUCCESS,
  NEW_FAILURE } from '../constants/action-types';
import { DateOnly } from '../utils';

export function getNewData(startDate, endDate) {
  return (dispatch, getState) => {
    const { session, details } = getState().newReducer;
    if (session !== DateOnly() || !details) {
      dispatch(newRequest());
      const data = {
        start_date: startDate,
        end_date: endDate
      };
      api.fetchPost('calendar', data)
        .then(({ data }) => {
          dispatch(newSuccess(data));
        })
        .catch(() => {
          dispatch(newFailure('error'));
        });
    }
  };
}

// action creators
function newRequest(payload) {
  return { type: NEW_REQUEST, payload };
}
function newSuccess(payload) {
  return { type: NEW_SUCCESS, payload };
}
function newFailure(payload) {
  return { type: NEW_FAILURE, payload };
}
