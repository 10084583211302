import React, {useRef,useEffect} from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Form, ListGroup } from 'react-bootstrap';

import styles from './Header.module.css';
import Wlogo from '../../assets/images/nxt.png';
import search from '../../assets/images/search.png';
import show from '../../assets/images/show.png';
import filterblue from '../../assets/images/filterblue.svg';
import filtertv from '../../assets/images/filtertv.svg';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';
// import HamburgerMenu from './HamburgerMenu';

export default function Header() {
  return (
    <Row className={styles.headerMenu}>
      {/* <Col md={2} xs={2} lg={2} sm={2} className={styles.headerHamburger}>
        <HamburgerMenu />
      </Col> */}
      <Col md={12} className={`${styles.recHeader} feedMhead rec__header d-flex align-items-center justify-content-center`}>
        <Link to="/" className="mainLogo">
          <img src={Wlogo} alt="Hlogo" />
        </Link>
      </Col>
    </Row>
  );
}

HeaderSearch.propTypes = {
  loading: PropTypes.bool,
  searchResults: PropTypes.array,
  query: PropTypes.string,
  getSearchData: PropTypes.func,
  searchResultClear: PropTypes.func,
  toggleFilters: PropTypes.func,
  filterToggle: PropTypes.bool
};
function HeaderSearch({searchResults, query, getSearchData, searchResultClear, toggleFilters, filterToggle}) {
  const inputRef = useRef(null);
  const ref = useRef(null);
  // Init a timeout variable to be used below
  let timeout = null;
  useEffect(() => {
    if (inputRef.current) {
      // Listen for keystroke events
      inputRef.current.addEventListener('keyup', function () {
        clearTimeout(timeout);
        timeout = setTimeout(function () {
          getSearchData(inputRef.current.value);
        }, 1000);
      });
    }
  }, []);
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      searchResults && searchResultClear();
    }
  }
  function HandleFilter() {
    toggleFilters();
  }
  useEffect(() => {
    if (filterToggle) {
      document.body.classList.add('c-modal');
    } else {
      document.body.classList.remove('c-modal');
    }
  }, [filterToggle]);
  useEffect(() => {
    if(searchResults && searchResults.length > 0) {
      document.body.classList.add('stopScroll');
    } else {
      document.body.classList.remove('stopScroll');
    }
  }, [searchResults]);
  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
  return (
    <>
      <Form className={'w-100 ' + (searchResults ? 'searchActive' : '')} ref={ref}>
        <Form.Group controlId="formBasicEmail" className="position-relative mb-0 d-flex justify-content-center">
          {/* {loading
            ? <div className={`${styles.Loading} spinner-grow`} role="status">
              <span className="sr-only">Loading...</span>
            </div>
            : <img src={search} alt="search" className={styles.searchIcon} />
          } */}
          <img src={search} alt="search" className={`${styles.searchIcon} __serachIcon`}/>
          <Form.Control
            type="text"
            placeholder="search"
            className={`${styles.recInput} form-control _recInput`}
            // onChange={getSearchData}
            onClick={(val) => getSearchData(val.target.value)}
            autoComplete={'off'}
            defaultValue={query}
            ref={inputRef}
          />
          <span className={`${styles.baseLine} baseLine`}/>
        </Form.Group>
        <ResultList searchResults={searchResults} searchResultClear={searchResultClear} />
      </Form>
      <span className={`${styles.filterWrapper} __filterWrapper ${filterToggle ? 'filterActive' : ''}`}>
        <span className={`${styles.filterToggle} filterBg`} onClick={HandleFilter}>
          <img src={`${filterToggle ? filterblue : filtertv }`} alt="filter" className={styles.filterIcon}/>
        </span>
      </span>
    </>
  );
}

ResultList.propTypes = {
  searchResults: PropTypes.array,
  searchResultClear: PropTypes.func
};
function ResultList({searchResults, searchResultClear}) {
  const history = useHistory();
  function RedirectRecoRoute(topicId) {
    history.push(`/recommendations/${topicId}`);
    searchResultClear();
  }
  return (
    <Col className={`${styles.searchDropdown} searchDropdown bg-white pl-0 pr-0`}>
      <ListGroup>
        {searchResults && searchResults.length === 0 &&
          <ListGroup.Item>
            <span>{'No results found'}</span>
          </ListGroup.Item>
        }
        {searchResults && searchResults.map(searchResult => {
          const { id, name, display_name, entity_type, thumbnail } = searchResult;
          // const custom_type = entity_type === 'Show' ? 'TV Show' : entity_type;
          const thumb = thumbnail ? thumbnail : show;
          return (<ListGroup.Item
            key={id}
            className='border-left-0 border-top-0 border-right-0 pb-1 pt-1 pl-2 pr-2 d-flex align-items-center'
            onClick={() => RedirectRecoRoute(id)}
          >
            <span className={styles.imgWrap}>
              <img className={styles.listImg} src={thumb} alt="infoImage"
                onError={(e)=>{e.target.onerror = null; e.target.src = show;}} />
            </span>

            <span
              className={`${styles.listlabel} listlabel`}
            >
              <EllipsisWithTooltip placement="bottom">{display_name || name} </EllipsisWithTooltip>
            </span>

            <span className={`${styles.listInfo} listInfo`}><EllipsisWithTooltip placement="bottom">{entity_type}</EllipsisWithTooltip></span>
          </ListGroup.Item>);
        })}
      </ListGroup>
    </Col>
  );
}
