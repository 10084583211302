import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col, ButtonGroup, Button } from 'react-bootstrap';

import styles from './Footer.module.css';

Footer.propTypes = {};

export default function Footer() {
  return (
    <Row>
      <Col md={12} className={`${styles.recfooter} rec__footer`}>
        <ButtonGroup aria-label="footer label">
          <ButtonWithLink text={'Feedback'} url={'/feedback'} />
          <ButtonWithLink text={'Terms of Service'} url={'/terms-of-service'}/>
          <ButtonWithLink text={'Privacy Policy'} url={'/privacy'}/>
        </ButtonGroup>
      </Col>
    </Row>
  );
}

const ButtonWithLink = ({text, url}) => {
  return (
    <Button className={`${styles.fbtn} rec__fbtn `} variant="link">
      <Link to={url} target="_blank">
        {text}
      </Link>
    </Button>
  );
};

ButtonWithLink.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string
};
